import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Header2 from '../components/Header2'
function TermsandConditonpage() {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className="bg-light">
            <Header2 />
            <article>
                <div className="container">
                    <div className="row justify-content-center gutter-1">
                     
                        <div className="col-lg-12">
                            <div className="card card-post card-post-lg">
                                <div className="card-body p-4">
                                    <ul className="list list--horizontal list--separated text-uppercase fs-14">
                                        <li><a href="" className="underline">Updated</a></li>
                                        <li><time datetime="2019-08-24 20:00" className="text-muted">01 Oct, 2022</time></li>
                                    </ul>
                                    <h2 className="card-title"><a href="">Terms and Conditions</a></h2>
                                    <p className="card-text">
                                        <ul className="list list-ordered pt-2">
                                            <li className="mb-2">Tata Starbucks Private Limited (“Starbucks India”) will be launching “Subscription”
                                                with technology partner Dotpe Private Limited and Logistics partner Blue Dart Express Ltd and
                                                marketing partner BlobCity iSolutions Private Limited</li>
                                            <li className="mb-2">Customers can place their orders though a dedicated link or Registered / Verified
                                                Starbucks</li>
                                            <li className="mb-2">Customer needs to be at least 18 years of age or above to place an order.</li>
                                            <li className="mb-2">The menu for Subscription can be viewed at http://starbucks.dotpe.in/subscription,
                                                where you can place your subscription orders and make payments</li>
                                            <li className="mb-2">Customer should place order only through the above stated means, request for
                                                subscription directly at store cannot be fulfilled</li>
                                            <li className="mb-2">The digital receipt visible on order confirmation is not the final tax bill. The Final
                                                GST invoice by Starbucks India will be received by the customer along with his Delivery Package</li>
                                            <li className="mb-2">Starbucks India does not guarantee availability of all items at all times for the
                                                subscription</li>
                                            <li className="mb-2">Only delivery addresses serviceable by our logistics partner will be accepted by the
                                                portal.
                                                Addresses out of the reach of delivery partner will not be serviced. It is the customer’s
                                                responsibility to ensure that he enters his correct and complete address including landmark & PIN Code
                                            </li>
                                            <li className="mb-2">In case of cancellation of order by the customer, no refund will be provided</li>
                                            <li className="mb-2">Delivery of coffee will not be made sooner than 10 days from the date of
                                                ordering/purchase of subscription</li>
                                            <li className="mb-2">Customer will not be able to make any changes to his subscription services or cancel
                                                the order once placed</li>
                                            <li className="mb-2">Amount payable will have to be paid by the customer in advance. Cash on Delivery option
                                                is not available</li>
                                            <li className="mb-2">Any product ordered from subscription should not be used for resale purpose</li>
                                            <li className="mb-2">Marketing partner on best effort basis will create promotional materials and optimize
                                                marketing campaigns by using proprietary AI marketing automation tools. Further, customers may receive
                                                SMSs and emailers from time to time in relation to their order or interest </li>
                                            <li className="mb-2">Starbucks India WhatsApp number is “91 84540 56333” which is verified business account.
                                                Starbucks does not ask for any Financial Bank OTPs or banking related details. Customers are urged to
                                                spot suspicious WhatsApp message & interact only with the verified business account</li>
                                        </ul>
                                    </p>
                                    <p>
                                        <h3>T&C related to Product</h3>
                                        <ul style={{ listStyleType: "decimal" }} className="pl-3">
                                            <li className="pl-1">Customer is made aware that if they select ground coffee, Tata Starbucks would be
                                                required to open the packet, grind the whole beans as per the specifications of the customer, and
                                                place the product back in the same pouch, and reseal the same for dispatch to the customer;</li>
                                            <li className="pl-1">Shelf life declaration: Whole coffee beans are best before 8 months for the date of
                                                packaging. This may not be entirely applicable for ground beans / coffee powder.</li>
                                        </ul>
                                    </p>

                                    <p>
                                        <h3>T&C related to At Home Coffee via Subscription & My Starbucks Reward Program:</h3>
                                        <ul style={{ listStyleType: "decimal" }} className="pl-3">
                                            <li className="pl-1">My Starbucks rewards members are eligible to earn stars on payments made for these
                                                orders. However, the rewards will reflect into member’s account within 4 working days.</li>
                                            <li className="pl-1">My Starbucks Rewards members will not be able to redeem their earned benefits for these
                                                orders.</li>
                                            <li className="pl-1">Delivery Order needs to be placed from same mobile number which is registered in the My
                                                Starbucks Reward Program.</li>
                                            <li className="pl-1">Payment through registered Starbucks physical card & the Starbucks India app will not
                                                be available as a mode of payment</li>

                                            <li className="pl-1">Shelf life declaration: Whole coffee beans are best before 8 months for the date of
                                                packaging. This may not be entirely applicable for ground beans / coffee powder.</li>
                                        </ul>
                                    </p>



                                    <p>
                                        <h4>The Starbucks India Privacy Policy resides here: </h4>
                                        http://www.starbucks.in/aboutus/companyinformation/online-policies/privacy-statement</p>
                                    <p>
                                        <h4>For any additional terms and conditions, kindly visit the link: </h4>
                                        https://www.starbucks.in/media/Starbucks_Coffee_At_Home_via_Subscription_tcm87-68110.pdf</p>
                                    <p>
                                        <h4>CONTACT FOR CLARIFICATION: </h4>For information/clarification/queries, please contact us at
                                        Customercare@tatastarbucks.comwith your query clearly mentioned as the subject of your e-mail</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <Footer />
        </div>
    )
}
export default TermsandConditonpage
