import React from 'react'
import Header from '../components/Header'
import Login from '../components/Login'
import Footer from '../components/Footer'
import Header2 from '../components/Header2'
function Loginpage () {
  return (
    <div className="bg-light">
    <Header2/>
    <Login/>
    <Footer/>
    </div>
  )
}

export default Loginpage
