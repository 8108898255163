import React from 'react'

import Footer from '../components/Footer'
import Orderdetails from '../components/Orderdetails'
import Header2 from '../components/Header2'
import { Link } from "react-router-dom";
function Accountorderdetails() {
    return (
        <div className='bg-light'>
            <Header2 />
          <div className='container'>
          <div className='row'>
                <div className='col-8'>
                    <div className="col m-1"><h2 className="cart-item-title">Hello Saher!</h2></div>
                </div>
                
            </div>
            <div className='row'>
            <div className='col-8'>
            <Link to="/" className="col text-right">
                            <div className="btn btn-outline-primary">+ New Subscription</div>
                        </Link>
                </div>

            </div>
            
            <Orderdetails />
            <Orderdetails />
          </div>




            <Footer />
        </div>
    )
}

export default Accountorderdetails