import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import indianestateblendimage from '../images/products/2.png'
import OrderTotal from '../components/OrderTotal'
import PaymentDetails from '../components/PaymentDetails'
import DeliveryAddress from '../components/DeliveryAddress'
import "./NewSubsribtion.css"
import { Link } from "react-router-dom";
import Header2 from '../components/Header2'
import { useNavigate } from "react-router-dom";
function Subscribitionpage() {

    const [openaddress, setopenaddress] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState(JSON.parse(localStorage.getItem('price')))
    const [selected2Option, setSelected2Option] = React.useState(JSON.parse(localStorage.getItem('delivery')))
    const [product, setproduct] = React.useState(JSON.parse(localStorage.getItem('productitems')))
    const [price, setprice] = React.useState(localStorage.getItem('mainprice'))
    const [frequency, setfrequency] = React.useState(JSON.parse(localStorage.getItem('mainfrequncy')))

    React.useEffect(() => {

        if (price == null && product !== null ) {
            console.log("asda")
            window.location.reload()
        } else {
            console.log("assdfsdfda")
        }


    }, [])

    React.useEffect(() => {
        navigate(0)

        localStorage.setItem('price', JSON.stringify(selectedOption));
        localStorage.setItem('delivery', JSON.stringify(selected2Option));

        setTimeout(function () {
            setSelectedOption(JSON.parse(localStorage.getItem('price')))
        }, 1000);
        setTimeout(function () {
            setSelected2Option(JSON.parse(localStorage.getItem('delivery')))
        }, 1000);

        setpriceandfrequency()

    }, [selected2Option, selectedOption])
    const navigate = useNavigate();

    function setpriceandfrequency() {
        console.log("main1:", price, frequency)
        console.log("main:", JSON.parse(localStorage.getItem('mainprice')), JSON.parse(localStorage.getItem('mainfrequncy')))
        if (selectedOption === "2") {
            localStorage.setItem('mainprice', JSON.stringify(product?.twopacket));
            console.log("mai3:", JSON.parse(localStorage.getItem('mainprice')), JSON.parse(localStorage.getItem('mainfrequncy')))
            if (selected2Option == "1") {
                localStorage.setItem('mainfrequency', JSON.stringify("1 packet, every 15 days"));

            } else if (selected2Option == "2") {
                localStorage.setItem('mainfrequency', JSON.stringify("1 packet, every 30 days"));

            }
        } else if (selectedOption === "6" && product?.coffeetype === "beans") {
            localStorage.setItem('mainprice', JSON.stringify(product?.sixpacket));

            if (selected2Option == "1") {
                localStorage.setItem('mainfrequency', JSON.stringify("1 packet, every 15 days"));

            } else if (selected2Option == "2") {
                localStorage.setItem('mainfrequency', JSON.stringify("1 packet, every 30 days"));
            } else if (selected2Option == "3") {
                localStorage.setItem('mainfrequency', JSON.stringify("2 packets, every 30 days"));
            }
        } else if (selectedOption === "12" && product?.coffeetype === "beans") {
            localStorage.setItem('mainprice', JSON.stringify(product?.twelvepackets));
            if (selected2Option == "1") {
                localStorage.setItem('mainfrequency', JSON.stringify("1 packet, every 15 days"));
            } else if (selected2Option == "2") {
                localStorage.setItem('mainfrequency', JSON.stringify("2 packets, every 30 days"));
            }
        } else if (selectedOption === "12" && product?.coffeetype === "instant") {
            localStorage.setItem('mainprice', JSON.stringify(product?.twelvepackets));
            if (selected2Option == "1") {
                localStorage.setItem('mainfrequency', JSON.stringify("2 packets, every 30 days"));

            } else if (selected2Option == "2") {
                localStorage.setItem('mainfrequency', JSON.stringify("3 packets, every 30 days"));
            } else if (selected2Option == "3") {
                localStorage.setItem('mainfrequency', JSON.stringify("4 packets, every 30 days"));
            }
        } else if (selectedOption === "6" && product?.coffeetype === "instant") {
            localStorage.setItem('mainprice', JSON.stringify(product?.sixpacket));
            if (selected2Option == "1") {
                localStorage.setItem('mainfrequency', JSON.stringify("1 packet, every 15 days"));
            } else if (selected2Option == "2") {
                localStorage.setItem('mainfrequency', JSON.stringify("2 packets, every 30 days"));
            } else if (selected2Option == "3") {
                localStorage.setItem('mainfrequency', JSON.stringify("3 packets, every 30 days"));
            }
            // setTimeout(function() {
            //     setprice(JSON.parse(localStorage.getItem('mainprice')))
            //   }, 1000);
            //   setTimeout(function() {
            //     setfrequency(JSON.parse(localStorage.getItem('mainfrequency')))
            //   }, 1000);
            console.log("main:", JSON.parse(localStorage.getItem('mainprice')), JSON.parse(localStorage.getItem('mainfrequncy')))
            setprice(JSON.parse(localStorage.getItem('mainprice')))
            setfrequency(JSON.parse(localStorage.getItem('mainfrequency')))
            console.log("main:", JSON.parse(localStorage.getItem('mainprice')), JSON.parse(localStorage.getItem('mainfrequncy')))

        }
    }
    function newclickhandler() {
        console.log(openaddress)
        setopenaddress(false)
    }
    function savedclickhandler() {

        setopenaddress(true)
    }
    const [addresses, setAddresses] = React.useState([
        {
            id: 1,
            name: 'Home',
            address: '123 Main St',
            city: 'Anytown',
            state: 'CA',
            zip: '90210',
            phone: '555-555-1212',
        },
        {
            id: 2,
            name: 'Work',
            address: '456 Market St',
            city: 'Anytown',
            state: 'CA',
            zip: '90210',
            phone: '555-555-1212',
        },
    ]);
    const [selectedAddress, setSelectedAddress] = React.useState(null);
    const handleSelectAddress = (event) => {
        const id = parseInt(event.target.value, 10);
        const selected = addresses.find((address) => address.id === id);
        setSelectedAddress(selected);
    };
    return (
        <div className="bg-light">
            <Header2 />
            <section>
                <div class="container">

                    <div class="row gutter-1">

                        <div class="col">



                            <div className="col-lg-12">
                                <div className="bg-white p-2 p-md-3">
                                   {
                                    product==null && <h6>No product selected</h6>
                                   }

                                   {
                                    product!==null &&  <div className="tab-content" id="myTabContent">

                                    <div className="row">
                                        <div className="col-12">

                                            <h2 className="pb-2">New Subscription</h2>
                                        </div>
                                        <div className="col-12">
                                            <div className="bordered cart-item-list p-1">
                                                <div className="cart-item">
                                                    <a href="#!" className="cart-item-image"><img src={product?.img} alt="Image" /></a>
                                                    <div className="cart-item-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 className="cart-item-title">{product?.productname}<a href="#"><br></br>

                                                                </a></h5>
                                                                <small className="cart-item-subtitle">{product?.Quantity}</small><br></br>
                                                                <small className="cart-item-subtitle">{product?.Type}</small><br></br>
                                                                <Link to="/"><small className="cart-item-subtitle" style={{ cursor: "pointer", color: "red" }}>Edit</small><br></br></Link>


                                                                <div>
                                                                    <ul className="list list--horizontal list--separated fs-14 text-muted mt-1">

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                   }

                                    <br>
                                    </br>
                                    {/* <div className="row">
                                        <form id="form">
                                            <label>Subscription Plan</label><br></br>
                                            <input type="radio" id="2-packets" name="subscription" value="" />
                                            <label for="child" className="ml-1 text-dark">2 packets for ₹1500 </label><br></br>
                                            <input type="radio" id="adult" name="subscription" value="adult" />
                                            <label for="adult" className="ml-1 text-dark">6 packets for ₹4275 (5% discount) </label><br></br>
                                            <input type="radio" id="senior" name="subscription" value="senior" />
                                            <label for="senior" className="ml-1 text-dark">12 packets for ₹8100 (10% discount) </label>
                                        </form>
                                    </div>

                                    <div className="row">
                                        <form id="form">
                                            <label>Delivery Frequency</label><br></br>
                                            <input type="radio" id="2-packets" name="subscription" value="" />
                                            <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                                            <input type="radio" id="adult" name="subscription" value="adult" />
                                            <label for="adult" className="ml-1 text-dark">1 packet, every 30 days</label><br></br>

                                        </form>


                                    </div> */}
                                   {
                                    product!==null &&  <div className="row">
                                    <form id="form">
                                        <label>SUBSCRIPTION PLAN</label><br></br>
                                        <input type="radio" id="2-packets" name="subscription" value="2" checked={selectedOption === '2'} onChange={event => setSelectedOption(event.target.value)} />
                                        <label for="child" className="ml-1 text-dark">2 packets for ₹{product?.twopacket} </label><br></br>
                                        <input type="radio" id="adult" name="subscription" value="6" checked={selectedOption === '6'} onChange={event => setSelectedOption(event.target.value)} />
                                        <label for="adult" className="ml-1 text-dark">6 packets for ₹{product?.sixpacket} (5% discount) </label><br></br>
                                        <input type="radio" id="senior" name="subscription" value="12" checked={selectedOption === '12'} onChange={event => setSelectedOption(event.target.value)} />
                                        <label for="senior" className="ml-1 text-dark">12 packets for ₹{product?.twelvepackets} (5% discount) </label>
                                    </form>
                                </div>
                                   }

                                    {selectedOption === "2" && (
                                        <div className="row">
                                            <form id="form">
                                                <label>DELIVERY FREQUENCY</label><br></br>

                                                <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                                                <input type="radio" id="adult" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="adult" className="ml-1 text-dark">1 packet, every 30 days</label><br></br>

                                            </form>


                                        </div>


                                    )}

                                    {selectedOption === "6" && product?.coffeetype === "beans" && (
                                        <div className="row">
                                            <form id="form">
                                                <label>DELIVERY FREQUENCY</label><br></br>

                                                <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                                                <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">1 packet, every 30 days</label><br></br>
                                                <input type="radio" id="adult" name="subscription" value="3" checked={selected2Option === '3'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="adult" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>

                                            </form>


                                        </div>


                                    )}

                                    {selectedOption === "6" && product?.coffeetype === "instant" && (
                                        <div className="row">
                                            <form id="form">
                                                <label>DELIVERY FREQUENCY</label><br></br>

                                                <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                                                <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>
                                                <input type="radio" id="adult" name="subscription" value="3" checked={selected2Option === '3'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="adult" className="ml-1 text-dark">3 packets, every 30 days</label><br></br>

                                            </form>


                                        </div>


                                    )}

                                    {selectedOption === "12" && product?.coffeetype === "beans" && (
                                        <div className="row">
                                            <form id="form">
                                                <label>DELIVERY FREQUENCY</label><br></br>

                                                <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                                                <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>
                                            </form>


                                        </div>


                                    )}

                                    {selectedOption === "12" && product?.coffeetype === "instant" && (
                                        <div className="row">
                                            <form id="form">
                                                <label>DELIVERY FREQUENCY</label><br></br>

                                                <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>
                                                <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">3 packets, every 30 days</label><br></br>
                                                <input type="radio" id="2-packets" name="subscription" value="3" checked={selected2Option === '3'} onChange={event => setSelected2Option(event.target.value)} />
                                                <label for="child" className="ml-1 text-dark">4 packets, every 30 days</label><br></br>
                                            </form>


                                        </div>


                                    )}
                                    {
                                        product?.coffeetype === "beans" && <div className="row">
                                            <form id="form" className="col-md-12">
                                                <label>Select Grind Size</label><br /><br /><br />
                                                <label for="child" className="ml-1 text-dark">Whole Bean</label>
                                                <input type='range' min='1' max='5' value='1' step='1' className="n n5 col-md-12" />


                                            </form>
                                        </div>
                                    }

                                    <hr></hr>
                                    <br></br>
                                    <div className="tab-pane fade show active" id="sidebar-1-5" role="tabpanel" aria-labelledby="sidebar-1-5">
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <h2>Delivery Address</h2>
                                            </div>
                                            {/* <div className="col-md-6 text-md-right">
                                                {openaddress===false && <a className="underline" onClick={()=>newclickhandler()}><i className="icon-plus"></i> Add new address</a>}
                                                {openaddress===true && <a className="underline" data-toggle="modal" data-target="#adresses"  onClick={()=>savedclickhandler()}><i className="icon-home"></i> Saved addresses</a>}
                                            </div> */}
                                        </div>
                                        {/* {openaddress==false &&    <div className="row gutter-2">
                                            <div className="col-lg-6">
                                                <div className="card card-data bordered">
                                                    <div className="card-header">
                                                        <div className="row align-items-center">
                                                            <div className="col-8">
                                                                <h2 className="card-title eyebrow">Address</h2>
                                                            </div>
                                                            <div className="col text-right">
                                                                <span className="dropdown">
                                                                    <button className="btn btn-lg btn-white btn-ico" id="dropdown-5" data-toggle="dropdown"
                                                                        aria-haspopup="true" aria-expanded="false" type="button"><i
                                                                            className="icon-more-vertical"></i></button>
                                                                    <span className="dropdown-menu" aria-labelledby="dropdown-5">
                                                                        <a className="dropdown-item" href="#!">Action</a>
                                                                        <a className="dropdown-item" href="#!">Another action</a>
                                                                        <a className="dropdown-item" href="#!">Something else here</a>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <p>
                                                            Jhon Doe <br />
                                                            1620 East Ayre Str <br />
                                                            Suite M3115662 <br />
                                                            Wilmington, DE 19804 <br />
                                                            United States
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>} */}
                                        <div className='row'>
                                            {addresses.map((address) => (
                                                <div className='col-8' key={address.id} sm="6" md="4">
                                                    <div className='row'>
                                                        <div className='col-1 d-flex justify-content-center'>

                                                            <input className=''
                                                                type="radio"
                                                                id={`address-${address.id}`}
                                                                name="selectedAddress"
                                                                value={address.id}
                                                                onChange={handleSelectAddress}
                                                                label={address.name}
                                                            />

                                                        </div>
                                                        <div className='col'>
                                                            <div className='card'>
                                                                <div className='card-body'>
                                                                    <h4 className='title'>{address.name}</h4>
                                                                    <h6 className='subtitle'>{address.address}</h6>
                                                                    <h6 className='card-subtitle '>
                                                                        {address.city}, {address.state} {address.zip}
                                                                    </h6>
                                                                    {/* <h6 className='card-subtitle '>{address.phone}</h6> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-1 d-flex justify-content-center'>
                                                            {/* <div className="d-flex justify-content-center m-auto" style={{ cursor: "pointer" }}><i className="icon-edit mr-2"></i></div> */}
                                                            <div className="d-flex justify-content-center m-auto" style={{ cursor: "pointer", color: "red" }}><i className="icon-delete"></i></div>
                                                        </div>

                                                    </div>

                                                    <hr></hr>
                                                </div>

                                            ))}
                                        </div>

                                        {openaddress === false && <button className="bg-primary text-white p-1" style={{ cursor: "pointer" }} onClick={() => savedclickhandler()}>Add a new address</button>}
                                        {openaddress === true && <button className="bg-primary text-white p-1" style={{ cursor: "pointer" }} onClick={() => newclickhandler()}>Saved address</button>}
                                        {/* <h6 style={{ cursor: "pointer" }}>Edit address</h6>
                                        <h6 style={{ cursor: "pointer", color:"red"}}>Delete address</h6> */}
                                    </div>
                                </div>
                                {openaddress === true && <DeliveryAddress />}
                                <hr>
                                </hr>
                                <div class="bg-white p-2 p-lg-3 md-1">
                                    <h2 class="mb-3 text-uppercase fs-20">Delivery</h2>
                                    <div class="custom-control custom-choice">

                                        {/* <!-- <label class="custom-control-label text-dark d-flex justify-content-between align-items-center" for="custom-choice-1"> --> */}
                                        <span>

                                            <span class="text-muted fs-16">Estimated first delivery: Tue, 01 Aug 2022</span>
                                        </span>

                                        {/* </label> */}
                                    </div>

                                </div>
                            </div>

                        </div>


                        {/* <OrderTotal /> */}
                        {/* ................ */}
                        <aside className="col-lg-5">
                            <div className="bg-white p-2 p-lg-3">
                                <h2 className="mb-3 text-uppercase fs-20">Order total</h2>
                              {
                                product==null && <h6>Please select the product.</h6>
                              }

                              {
                                product!==null &&   <div className="cart-item">
                                <Link to="/:product" className="cart-item-image"><img src={product?.img} alt="Image" /></Link>
                                <div className="cart-item-body">
                                    <div className="row">
                                        <div className="col-9">
                                            <h5 className="cart-item-title">{product?.productname}</h5>
                                            <ul className="list list--horizontal fs-14">
                                                {/* <li>Grind: Turkish</li> */}
                                                <li>{frequency ?? JSON.parse(localStorage.getItem('mainfrequency'))}</li>

                                            </ul>
                                        </div>
                                        <div className="col-3 text-right">
                                            <ul className="cart-item-options">

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                              }

                                <hr className="my-3" />
                                 {
                                    product!==null &&    <>
                                      <ul className="list-group list-group-minimal">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Items total
                                        <span>₹{price ?? JSON.parse(localStorage.getItem('mainprice'))}</span>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center text-uppercase font-weight-bold">
                                        Total to pay
                                        <span>₹{price ?? JSON.parse(localStorage.getItem('mainprice'))}</span>
                                    </li>
                                </ul>
                                <hr></hr>
                                <a> <small>*Price inclusive of all shipping and taxes </small></a>
                                    </>
                                 }
                                
                            </div>
                         {
                            product!==null &&    <div className="col-md-12 pt-1">
                            <Link to="/order-received" className="btn btn-block btn-primary">Pay Now</Link>
                        </div>
                         }

                            {
                                product==null &&   <div className="col-md-12 pt-1">
                                <Link to="/order-received" className="btn btn-block btn-primary disabled">Pay Now</Link>
                            </div>
                            }
                        </aside>
                        {/* ........... */}
                    </div>
                </div>
            </section>


            <Footer />
        </div>
    )
}

{/* </div>
        </div>
    </div > */}

export default Subscribitionpage
