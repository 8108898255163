import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Homepage from '../components/Homepage'
import data from "./data"

function Landingpage (){
    console.log(data)
  return (
    <>
    <Header/>
    <Homepage data = {data}/>
    <Footer/>
    </>
  )
}

export default Landingpage
