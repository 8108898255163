import React, { Children } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import {useNavigate} from "react-router-dom"
function Privaterouting(props)  {

console.log("private")

const navigate = useNavigate();
   React.useEffect(()=>{

   let password =  JSON.parse(localStorage.getItem('password'));
   if(!password){
    navigate("/enter-password")
   }
   },[])
  return props.children
    }

export default Privaterouting;