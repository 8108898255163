import React from 'react'
import { Link } from "react-router-dom";
function Otpverification() {
    return (
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div className="card boxed">
                            <div className="card-header">
                                <ul className="nav nav-tabs nav-fill card-header-tabs lavalamp" id="component-1" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#component-1-1" role="tab" aria-controls="component-1-1" aria-selected="true">OTP Verification </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="component-1-content">

                                    <div className="col-12 py-1" style={{ fontSize: "90%" }}><small>An OTP has been sent to XXX XXX 4369</small></div>
                                    <div className="tab-pane fade show active" id="component-1-1" role="tabpanel" aria-labelledby="component-1-1">
                                        <div className="row gutter-2">
                                            <div className="col-12">
                                                <fieldset>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-label-group">
                                                                <input type="text" id="inputPhone" className="form-control form-control-lg" placeholder="Email address" required="" maxlength="10" />
                                                                <label for="inputPhone">Enter OTP</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </fieldset>
                                            </div>

                                            <div className="col-12">
                                 
                                                <div className="row">
                                                    <div class="col-6">
                                                    <Link className="btn btn-outline-primary btn-block ">Resend otp</Link>
                                                    </div>
                                                    <div className="col-6">
                                                    <Link to="/personal-details" className="btn btn-primary btn-block">Verify OTP</Link>
                                                    </div>
                                               
                                                </div>
                                                {/* <Link to="/personaldetails" className="btn btn-outline-primary btn-block ">Resend otp</Link>
                                                <Link to="/personaldetails" className="btn btn-primary btn-block">Verify & Proceed</Link> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Otpverification
