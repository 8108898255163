import sumtraimage from '../images/products/1.png'
import indianestateblendimage from '../images/products/2.png'
import readybewitalianimage from '../images/products/italian-roast.png'
import colombiaimage from '../images/products/colombia.png'
import italianroastimage from '../images/products/4.png'
import kenyaimage from '../images/products/3.png'
import viainstant from '../images/products/Via-Instant.png'
import wholebeans from '../images/products/Whole-Bean.png'
// import sumtraimage1 from '../images/products/1.1.jpg'
// import sumtraimage2 from '../images/products/1.2.jpg'
// import indianestateblendimage1 from '../images/products/2.1.jpg'
// import indianestateblendimage2 from '../images/products/2.3.jpg'
// import kenyaimage1 from '../images/products/3.1.jpg'
// import kenyaimage2 from '../images/products/3.2.jpg'
// import italianroastimage1 from '../images/products/4.1.jpg'
// import italianroastimage2 from '../images/products/4.2.jpg'
// import colombiaimage1 from '../images/products/colombia.1.jpeg'
// import colombiaimage2 from '../images/products/colombia.3.jpeg'
// import readybewitalianimage1 from '../images/products/italian-roast.1.jpeg'
// import readybewitalianimage2 from '../images/products/italian-roast.3.jpeg'


const data = [
    {
        id: 1,
        coffeetype:"beans",
        link:"sumatra",
        productname: "Sumatra",
        Type: "Dark Roast",
        Quantity: "250gms",
        Price: 750,
        twopacket: 1500,
        sixpacket: 4275,
        twelvepackets: 8550,
        tastingnotes: "Earthy & Herbal",
        origin: "Asia / Pacific",
        img:sumtraimage,
        // img2:sumtraimage1,
        // img3:sumtraimage2,
        img4:wholebeans,
        desc: "Sumatra has a full, buttery body with virtually no acidity, so the coffee's intensity and flavour simply lingers on your tongue. Make no mistake, this is a slurping coffee. At its best, there's no mistaking the concentrated herbal notes and earthy aroma - telltale marks this beloved brew have left on our shirts and our hearts."
    },
    {
        id: 2,
        coffeetype:"beans",
        link:"india-estates-blend",
        productname: "India Estates Blend",
        Type: "Medium Roast",
        Quantity: "250gms",
        Price: 750,
        twopacket: 1500,
        sixpacket: 4275,
        twelvepackets: 8550,
        tastingnotes: "Chocolate & Herbal",
        origin: "Asia / Pacific",
        img:indianestateblendimage,
        // img2:indianestateblendimage1,
        // img3:indianestateblendimage2,
        img4:wholebeans,
        desc: "India Estates Blend - Cultivated in Karnataka in harmony with nature, the lush and layered coffee captivates from first sip. In the dappled light beneath shade trees, the gently sloping land and fertile red soil yield the finest Indian coffee we've ever experienced. Our master roasters lovingly revealed its distinctive complexity—lush herbal notes, hints of citrus and a chocolaty mouthfeel."
    },

    {
        id: 3,
        coffeetype:"beans",
        link:"kenya",
        productname: "Kenya",
        Type: "Medium Roast",
        Quantity: "250gms",
        Price: 750,
        twopacket: 1500,
        sixpacket: 4275,
        twelvepackets: 8550,
        tastingnotes: "Juicy & Complex",
        origin: "Africa / Arabia",
        img:kenyaimage,
        // img2:kenyaimage1,
        // img3:kenyaimage2,
        img4:wholebeans,
        desc: "Kenya - Beloved, like the elephant that symbolizes it. Bold, like the sun coming up over the Great Rift Valley. And big - awash with a full-bodied juiciness that makes it instantly recognizable to its many, many fans. These qualities are all true of our Kenya coffee. You can recognize Kenya through its tell-tale citrus flavours - grapefruit most often, but sometimes lemon and sometimes tart orange. There's a sparkle to this coffee, a bright acidity, that makes it so refreshing to drink. In fact, it's a terrific iced coffee on a hot summer day."
    },

    {
        id: 4,
        coffeetype:"beans",
        link:"italian-roast",
        productname: "Italian Roast",
        Type: "Dark Roast",
        Quantity: "250gms",
        Price: 750,
        twopacket: 1500,
        sixpacket: 4275,
        twelvepackets: 8550,
        tastingnotes: "Roasty & Sweet",
        origin: "",
        img:italianroastimage,
        // img2:italianroastimage1,
        // img3:italianroastimage2,
        img4:wholebeans,
        desc: `The Italians have a saying, la dolce far niente, which translates as "the sweetness of doing nothing." That's an apt description of our favourite way to enjoy Italian Roast - just ourselves, two cups, and an afternoon to spare. This coffee gets its distinctive sweetness from the way it is roasted: dark, and darker still. Somewhere beyond the caramel notes of our Espresso Roast but short of the smokiness that identifies our French Roast - that is the sweet spot held by Italian Roast. What is created after the coffee cools is a sturdy and sweet blend, potent and intense.`


    },
    {
        id: 5,
        coffeetype:"instant",
        link:"via-colombia",
        productname: "Colombia",
        Type: "VIA Ready Brew - Medium Roast",
        Quantity: "12 sticks",
        Price: 650,
        twopacket: 1300,
        sixpacket: 3705,
        twelvepackets: 7410,
        tastingnotes: "Balanced & Nutty",
        origin: "Latin America",
        img:colombiaimage,
        // img2:colombiaimage1,
        // img3:colombiaimage2,
        img4:viainstant,
        desc: "How far do we go for a better cup of Colombian coffee? Six thousand feet - straight up. Sounds extreme, we know. But high atop the majestic Andes, in a rugged landscape of simmering volcanoes, is where the finest coffee beans in Colombia like to grow. And just as there are no shortcuts through the dirt paths that crisscross the sheer slopes, we take none when it comes to nurturing these treasured cherries to gourmet perfection. This Colombian marvel erupts on the palate with a juicy feel and robust flavors, a testament to the hearty riches of volcanic soils. Its remarkable finish, dry with hints of walnut, lifts this superior coffee into a class of its own. One sip and you'll agree it's worth every step of the climb."

    },
    {
        id: 6,
        coffeetype:"instant",
        link:"via-italian-roast",
        productname: "Italian Roast",
        Type: "VIA Ready Brew - Dark Roast",
        Quantity: "12 sticks",
        Price: 650,
        twopacket: 1300,
        sixpacket: 3705,
        twelvepackets: 7410,
        tastingnotes: "Roasty & Sweet",
        origin: "",
        img:readybewitalianimage,
        // img2:readybewitalianimage1,
        // img3:readybewitalianimage2,
        img4:viainstant,
        desc: `Now you can enjoy our bold and roasty-sweet Italian Roast in an instant. Just tear open a pack of Starbucks VIA® Ready Brew and add water. Let the coffee brew 10 seconds, stir, savor and enjoy. It's not instant coffee the way you know it. This is genuine, dark-roasted Starbucks® coffee. 100% arabica and ethically sourced. Isn't it wonderful? There's now an instant coffee that you can start a whole new tradition with.`
    }
]


export default data