import './App.css';
import { Routes, Route, useParams } from 'react-router-dom';
import Landingpage from './pages/Landingpage';
import Loginpage from './pages/Loginpage';
import Otpverificationpage from './pages/Otpverificationpage';
import PersonaldetailsPage from './pages/PersonaldetailsPage';
import Checkoutpage from './pages/Checkoutpage';
import Productdetailspage from './pages/Productdetailspage';
import TermsandConditonpage from './pages/TermsandConditonpage';
import Accountorderdetailspage from './pages/Accountorderdetailspage';
import Orderreceivedpage from './pages/Orderreceivedpage';
import Subscribitionpage from './pages/Subscribitionpage';
import Accountorderdetails from './pages/Accountorderdetails';
import Contactus from './pages/Contactus';
import Privaterouting from './pages/Privaterouting';
import Passwordpage from './pages/Passwordpage';

function App() {
  return (
    <>

      <Routes>
        <Route path="/" element={<Privaterouting>
          <Landingpage />
        </Privaterouting>} />
        <Route path="/login" element={<Privaterouting><Loginpage/></Privaterouting>} />
        <Route path="/otp" element={<Privaterouting><Otpverificationpage/></Privaterouting> } />
        <Route path="/personal-details" element={<Privaterouting><PersonaldetailsPage/></Privaterouting> } />
        {/* <Route path="/checkout" element={<Checkoutpage/>} /> */}
        <Route path="/:product" element={<Privaterouting><Productdetailspage/></Privaterouting>} />
        <Route path="/terms-and-conditions" element={<Privaterouting><TermsandConditonpage/></Privaterouting>} />
        <Route path="/accountorderdetails" element={<Privaterouting><Accountorderdetailspage/></Privaterouting>} />
        <Route path="/order-received" element={<Privaterouting><Orderreceivedpage/></Privaterouting>} />
        <Route path="/new-subscription" element={<Privaterouting><Subscribitionpage/> </Privaterouting>} />
        <Route path="/my-subscription" element={<Privaterouting><Accountorderdetails/></Privaterouting> } />
        <Route path="/contact-us" element={<Privaterouting><Contactus/></Privaterouting> } />
        <Route path="/enter-password" element={<Passwordpage/> } />
      </Routes>


    </>
  );
}

export default App;

{/* <Routes>
<Privaterouting path="/" element={<Landingpage />} />
<Route path="/login" element={<Loginpage/>} />
<Route path="/otp" element={<Otpverificationpage/> } />
<Route path="/personaldetails" element={<PersonaldetailsPage/> } />
<Route path="/checkout" element={<Checkoutpage/>} />
<Route path="/:product" element={<Productdetailspage/>} />
<Route path="/terms-and-conditions" element={<TermsandConditonpage/>} />
<Route path="/accountorderdetails" element={<Accountorderdetailspage/>} />
<Route path="/orderreceived" element={<Orderreceivedpage/>} />
<Route path="/subscribitionpage" element={<Subscribitionpage/> } />
<Route path="/mysubscribtion" element={<Accountorderdetails/> } />
<Route path="/Contactus" element={<Contactus/> } />
<Route path="/enterpassword" element={<Passwordpage/> } />
</Routes> */}
