import React from 'react'
import suscibeimage from '../images/subscribe-to-starbucks.png'
import { Link } from "react-router-dom";
function Footer() {

    const styles = {
        position:"absolute" ,left:"50%" ,top:"0", transform:"translate(-50%,-37%)" ,width:"14.375rem", maxWidth:"40%"
    }

    return (
        <footer className="bg-dark pb-0 sbx-footer sbx-us-footer" >

            <div  style={{ position:"absolute",left:"50%" ,top:"0", transform:"translate(-50%,-37%)" ,width:"14.375rem", maxWidth:"40%"}}>
                <img src={suscibeimage} className="footer-logo ls-is-cached lazyloaded" alt="logo"  />
            </div>
            <div className="bg-dark text-white">
                <div className="container">
                    <div className="row align-items-center gutter-1">
                        <div className="col-md-8">
                            <p class="small text-muted">&copy; 2023 Starbucks Coffee Company. All rights reserved.</p>
                        </div>
                        <div className="col-md-4 text-muted py-2 d-flex align-items-center justify-content-center">
                            <ul class="list list--horizontal list--separated">
                                <li>
                                    <Link class="small underline" to="/terms-and-conditions">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link class="small underline" to="/terms-and-conditions">Terms</Link>
                                </li>
                                <li>
                                    <Link class="small underline" to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer