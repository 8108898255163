import React from 'react'
import Header from '../components/Header'
import Personaldetails from '../components/Personaldetails'
import Footer from '../components/Footer'
import Header2 from '../components/Header2'
function PersonaldetailsPage (){
  return (
    <div className="bg-light">
    <Header2/>
    <Personaldetails/>
    <Footer/>
    </div>
  )
}

export default PersonaldetailsPage
