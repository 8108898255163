import React from 'react'
import { Link } from "react-router-dom";
function Accountcard() {
    return (
        <div className="col-lg-8">
            <div className="bg-white p-2 p-md-3">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade active show" id="sidebar-1-1" role="tabpanel" aria-labelledby="sidebar-1-1">
                        <div className="row align-items-end">
                            <div className="col">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="#!">Completed&nbsp;</Link><i className="icon icon-check-circle" style={{paddingTop: "2px", color:"#006241"}}></i></li>


                                    </ol>
                                </nav>
                                <br />
                                <h3 className="cart-item-title">Sumatra</h3>

                                <small
                                    className="cart-item-subtitle">Subscription ended on 30 Aug 2022</small>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="bordered">
                                <ul className="order-meta">

                                    <li>
                                        <h5 className="order-meta-title">Order #</h5>
                                        <span>12339201</span>
                                    </li>
                                    <li>
                                        <h5 className="order-meta-title">Date</h5>
                                        <span>23 July 2022</span>
                                    </li>
                                    <li>
                                        <h5 className="order-meta-title">Total</h5>
                                        <span>₹1500</span>
                                    </li>
                                    <li>
                                        <h5 className="order-meta-title">Delivered</h5>
                                        <span>2 out of 2</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-md-6">
                            <a href="" className="btn btn-outline-primary text-center col-md-6"
                                style={{ backgroundColor: "#006241", color: "white" }}>Reorder</a>
                        </div>
                        <div className="col col-md-6">
                            <a href="" className="btn btn-outline-primary text-right">View Details</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div >
  )
}

export default Accountcard
