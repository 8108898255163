import React from 'react'
import Header from '../components/Header'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import Accountcard from '../components/Accountcard'
import { Link } from "react-router-dom";
function Accountorderdetailspage() {
    return (
        <div className="bg-light">
        <Header2/>
            <section style={{padding:"2rem 0"}}>
                <div className="container">
                    <div className="col text-left"><h2 className="cart-item-title">Hello Saher!</h2></div>
                    <div className="row gutter-1 gutter-md-2">


                        <Link to="/" className="col text-right">
                            <div className="btn btn-outline-primary">+ New Subscription</div>
                        </Link>


                        <Accountcard />
                        <Accountcard />
                        <Accountcard />





                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Accountorderdetailspage
