import React from 'react'
import sumtraimage from '../images/products/1.png'
import indianestateblendimage from '../images/products/2.png'
import readybewitalianimage from '../images/products/italian-roast.png'
import italianroastimage from '../images/products/4.png'
import kenyaimage from '../images/products/3.png'
import columbiaimage from '../images/products/colombia.png'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Productrelated(props) {

    console.log(props.productout)
    // let finalproduct = props.alldata.filter(function(obj){
    //     if(obj.id!=props.productout.id){
    //         return obj
    //     }
    // })
    //need to why below is coming
    //react-dom.development.js:22839 Uncaught DOMException: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node local storage. 
    const navigate = useNavigate();
    function onhandleClick(product){
        localStorage.setItem('productitems', JSON.stringify(product));
        navigate(`/${product.link}`)
    }

    let productlist = props.alldata.map((product) => {
        return <div className="card card-product" style={{cursor:"pointer"}}>
            {/* <figure className="card-image">
                <Link to="/product">
                    <img src={product.img} alt="Image" loading="lazy" />
                </Link>
            </figure>
            <Link to="/product" className="card-body">
                <h3 className="card-title">{product.productname}</h3>
                <span className="price">₹{product.Price}</span>
            </Link> */}
                 <figure className="card-image">
                <div onClick={() => onhandleClick(product)}>
                    <img src={product.img} alt="Image" loading="lazy" />
                </div>
            </figure>
            <div className="card-body" onClick={() => onhandleClick(product)}> 
                <h3 className="card-title">{product.productname}</h3>
                <span className="price">₹{product.Price}</span> / 
                <small> ₹{product.Quantity}</small>
            </div>
        </div>
    })

    // let productlist = props.alldata.map((product) => {
    //     return <div class="owl-item cloned" style={{ width: '500px', marginRight: '4px' }}>
    //         <div class="card card-product">
    //             <figure class="card-image"><a href="/product"><img src={product.img} alt="Image" loading="lazy" /></a></figure><a class="card-body" href="/product">
    //                 <h3 class="card-title">{product.productname}</h3><span class="price">₹{product.Price}</span>
    //             </a>
    //         </div>
    //     </div>
    // })
    return (
        <section className="no-overflow">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2>Other products</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {/* ........... */}

                        {/* <div class="owl-carousel visible owl-loaded owl-drag" data-items="[4,3,2,1]" data-margin="4" data-nav="true" data-loop="true">
                            <div class="owl-stage-outer">
                                <div class="owl-stage" style={{ transform: `translate3d(${-1512}px, ${0}px,${0}px)`, transition: 'all 0s ease 0s', width: '6048px' }}>


                                    {productlist}






                                </div>
                            </div>
                            <div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                            <div class="owl-dots disabled"></div>
                        </div> */}
                        {/* ............ */}
                        <div className="owl-carousel visible" data-items="[4,3,2,1]" data-margin="4" data-nav="true" data-loop="true">
                        {/* <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="/product">
                                        <img src={kenyaimage} alt="Image" loading="lazy"/>
                                    </Link>
                                </figure>
                                <Link to="/product" className="card-body">
                                    <h3 className="card-title">Kenya</h3>
                                    <span className="price">₹750</span>
                                </Link>
                            </div>
                            <div className="card card-product">
                                <figure className="card-image">
                                  
                                    <Link to="/product">
                                        <img src={italianroastimage} alt="Image" />
                                    </Link>
                                </figure>
                                <Link to="/product" className="card-body">
                                    <h3 className="card-title">Italian Roast</h3>
                                    <span className="price">₹750</span>
                                </Link>
                            </div>
                            <div className="card card-product">
                                <figure className="card-image">
                                   
                                    <Link to="/product">
                                        <img src={indianestateblendimage} alt="Image" />
                                    </Link>
                                </figure>
                                <Link to="/product" className="card-body">
                                    <h3 className="card-title">India Estates Blend</h3>
                                    <span className="price">₹750</span>
                                </Link>
                            </div>
                            <div className="card card-product">
                                <figure className="card-image">
                                    
                                    <Link to="/product">
                                        <img src={columbiaimage} alt="Image" />
                                    </Link>
                                </figure>
                                <Link to="/product" className="card-body">
                                    <h3 className="card-title">VIA Columbia</h3>
                                    <span className="price">₹650</span>
                                </Link>
                            </div>
                            <div className="card card-product">
                                <figure className="card-image">
                                   
                                    <Link to="/product">
                                        <img src={readybewitalianimage} alt="Image" />
                                    </Link>
                                </figure>
                                <Link to="/product" className="card-body">
                                    <h3 className="card-title">VIA Italian Roast</h3>
                                    <span className="price">₹650</span>
                                </Link>
                            </div> */}
                        {productlist}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Productrelated
