import React from 'react'
import logo from '../images/logo.png'
import menusale1 from '../images/demo/menu-sale-1.jpg'
import menusale2 from '../images/demo/menu-sale-2.jpg'
import { Link } from "react-router-dom";
function Header  () {

    function logouthandler(){
        localStorage.removeItem('password')
        window.location.reload()
    }
  return (
    <header className="header">
    <div className="container">
        <div className="row">
            <nav className="navbar navbar-expand-lg navbar-light">
                <Link to="/" className="navbar-brand order-1 order-lg-2"><img style={{ width: "300px" }} src={logo} alt="Logo" /></Link>

                <div className="collapse navbar-collapse order-4 order-lg-1" id="navbarMenu">
                    <ul className="navbar-nav mr-auto">



                        <li className="nav-item dropdown-lg dropdown-hover">

                            <div className="dropdown-menu" aria-labelledby="navbarDropdown-3">
                                <div className="container">
                                    <div className="row gutter-1">
                                        <div className="col-lg-6">
                                            <div className="card card-tile">
                                                <figure className="card-image equal equal-50">
                                                    {/* <span className="image" style={{backgroundImage: `url(${menusale1})`}}></span> */}
                                                </figure>
                                                <div className="card-footer p-1">
                                                    <div className="bg-white p-2">
                                                        <div className="row align-items-center">
                                                            <div className="col">
                                                                <h4 className="fs-20"><a href="#">50% Sale on Cardigans</a></h4>
                                                            </div>
                                                            <div className="col text-right">
                                                                <a href="" className="underline">Shop Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card card-tile">
                                                <figure className="card-image equal equal-50">
                                                    {/* <span className="image" style={{backgroundImage: `url(${menusale2})`}}></span> */}
                                                </figure>
                                                <div className="card-footer p-1">
                                                    <div className="bg-white p-2">
                                                        <div className="row align-items-center">
                                                            <div className="col">
                                                                <h4 className="fs-20"><a href="#">20% Sale on Jewelery</a></h4>
                                                            </div>
                                                            <div className="col text-right">
                                                                <a href="" className="underline">Shop Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="collapse navbar-collapse order-5 order-lg-3" id="navbarMenu2">
                    <ul className="navbar-nav ml-auto position-relative">


                        {/* <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <a className="nav-icon dropdown-toggle" id="navbarDropdown-4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="icon-search d-none d-lg-inline-block"></i>
                                <span className="d-inline-block d-lg-none">Search</span>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown-4">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="searchForm" placeholder="Search for items and brands" />
                                </div>
                            </div>
                        </li> */}



                        <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <Link className="nav-icon" to="/login">
                                {/* <i className="icon-user d-none d-lg-inline-block"></i>
                                <Link to="/login" className="d-inline-block d-lg-none" target="_blank">Account</Link> */}
                                Login
                            </Link>

                        </li>


                        <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <Link className="nav-icon" role="button" to="/my-subscription">
                                {/* <i className="icon-user d-none d-lg-inline-block"></i> */}
                                My Subscription
                                {/* <Link to="/order" className="d-inline-block d-lg-none" target="_blank">Orders</Link> */}
                            </Link>

                        </li>
                        <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <Link className="nav-icon" role="button" onClick={()=>logouthandler()}>
                                {/* <i className="icon-user d-none d-lg-inline-block"></i> */}
                                Access out
                                {/* <Link to="/order" className="d-inline-block d-lg-none" target="_blank">Orders</Link> */}
                            </Link>

                        </li>





                    </ul>
                </div>

                

                <div className="order-2 d-flex d-lg-none" id="navbarMenuMobile">
                    <ul className="navbar-nav navbar-nav--icons ml-auto position-relative">


                        {/* <li className="nav-item">
                            <a href="" className="nav-icon"><i className="icon-search"></i></a>
                        </li>


                        <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <a href="" className="nav-icon"><i className="icon-shopping-bag"></i></a>
                        </li> */}

                        <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <a href="" className="nav-icon" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="icon-menu"></i>
                            </a>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
  )
}

export default Header
