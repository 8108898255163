import React from 'react'
import Header from '../components/Header'
import Otpverification from '../components/Otpverification'
import Footer from '../components/Footer'
import Header2 from '../components/Header2'
function Otpverificationpage ()  {
  return (
    <div className="bg-light">
    <Header2/>
    <Otpverification/>
    <Footer/>
    </div>
  )
}
export default Otpverificationpage
