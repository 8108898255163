import React from 'react'
import { Link } from "react-router-dom";
function DeliveryAddress() {
    return (
        <div className="bg-white p-2 p-lg-3 mb-2">
            {/* <div className="row gutter-1 align-items-center">
                <div className="col-md-6">
                    <h2 className="text-uppercase fs-20">Delivery Address</h2>
                </div>
                <div className="col-md-6 text-md-right">
                    <a className="underline" data-toggle="modal" data-target="#adresses"><i className="icon-home"></i> Saved addresses</a>
                </div>
            </div> */}

            <fieldset className="mb-2">
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <input type="text" id="inputName2" className="form-control form-control-lg" placeholder="Name" required="" />
                            <label for="inputName">First Name</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <input type="text" id="inputPhone2" className="form-control form-control-lg" placeholder="Phone Number" required="" />
                            <label for="inputSurname">Phone Number</label>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset className="mb-2">
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <input type="text" id="inputAddress" className="form-control form-control-lg" placeholder="Address" required="" value="" />
                            <label for="inputAddress">Address</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <input type="text" id="inputApt" className="form-control form-control-lg" placeholder="Apt, Suite, etc. (optional)" required="" />
                            <label for="inputApt">Apt, Suite, etc. (optional)</label>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset className="mb-2">
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <input type="text" id="inputCity" className="form-control form-control-lg" placeholder="City" required="" />
                            <label for="inputCity">City</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {/* <div className="select-frame">
                            <select className="custom-select custom-select-lg" id="customSelect1" data-placeholder="State">
                                <option label="state"></option>
                                <option value="1">MP</option>
                                <option value="2">UP</option>
                                <option value="3"></option>
                            </select>
                        </div> */}
                        <div className="form-label-group">
                            <input type="text" id="inputZip" className="form-control form-control-lg" placeholder="State" required="" />
                            <label for="inputZip">State</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-label-group">
                            <input type="text" id="inputZip" className="form-control form-control-lg" placeholder="Zip" required="" />
                            <label for="inputZip">Zip</label>
                        </div>
                    </div>
                </div>
            
            </fieldset>
        </div>

    )
}

export default DeliveryAddress
