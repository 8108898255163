import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from "react-router-dom";
import Header2 from '../components/Header2'
function Orderreceivedpage() {
    return (
        <div className="bg-light">
            <Header2 />
            <section>
                <div className="container">
                    <div className="bg-white p-3 mb-3">
                        <h1 className="text-uppercase fs-20 mb-3">Order Received</h1>
                        <div className="alert alert-success" role="alert">
                            Thank you. Your order has been received!
                        </div>

                        <div className="row align-items-end">
                            <div className="col pt-3">

                                <h3 className="cart-item-title">India Estates Blend</h3>
                            </div>

                        </div>



                        <div className="row">
                            <div className="col">
                                <div className="bordered">
                                    <ul className="order-meta">

                                        <li>
                                            <h5 className="order-meta-title">Order #</h5>
                                            <span>12339201</span>
                                        </li>
                                        <li>
                                            <h5 className="order-meta-title">Date</h5>
                                            <span>23 July 2022</span>
                                        </li>
                                        <li>
                                            <h5 className="order-meta-title">Total</h5>
                                            <span>₹1500</span>
                                        </li>
                                        <li>
                                            <h5 className="order-meta-title">Delivered</h5>
                                            <span>2 out of 6</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <p>Please check your inbox shortly, a confirmation email is on it's way</p>
                        <Link to="/" className="btn btn-primary">Continue Shopping</Link>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Orderreceivedpage
