import React from 'react'
import sumtraimage from '../images/products/1.png'
import { Link } from "react-router-dom";

function Prodcutdetails(props) {

  console.log(props.data)

  const [selectedOption, setSelectedOption] = React.useState('2')
  const [selected2Option, setSelected2Option] = React.useState('1')

  React.useEffect(() => {
    localStorage.removeItem("mainprice")
    localStorage.removeItem("mainfrequency")
  }, [])
  React.useEffect(() => {
    localStorage.setItem('price', JSON.stringify(selectedOption));
    localStorage.setItem('delivery', JSON.stringify(selected2Option));
  }, [selectedOption, selected2Option])

  const [ imgselect,setimgselect] = React.useState('')
  
  React.useEffect(()=>{
    setimgselect(props.data.img)
    
  },[])
  return (
    <section className="hero">
      <div className="container">
        <div className="row gutter-2 gutter-md-4 justify-content-between">


          <div className="col-lg-7">
            <div className="row gutter-1 justify-content-between">
              <div className="col-lg-9 order-lg-2">
                <div className="owl-carousel owl-carousel--alt gallery" data-margin="0" data-slider-id="2" data-thumbs="true" data-nav="true">
                  <figure>
                    <a href={props.data.img}>
                      <img style={{width:"100%",height:"600px"}}src={imgselect??props.data.img} alt="product" />
                    </a>

                  </figure>
                  {/* <figure>
                    <a href={props.data.img}>
                      <img src={props.data.img2} alt="images" />
                    </a>

                  </figure> */}


                </div>
                <div class="card-body">
                  <ul className="order-preview d-flex justify-content-center">
                    
                    <li><a  data-placement="top"><img onClick={()=>setimgselect(props.data.img)}style={{width:"100px",height:"80px"}}src={props.data.img} alt="img" /></a></li>
                    {/* <li><a  data-placement="top"><img onClick={()=>setimgselect(props.data.img2)}style={{width:"100px",height:"80px"}}src={props.data.img2} alt="img2" /></a></li> */}
                    {/* <li><a title="Dark Stained NY11 Dining Chair" data-toggle="tooltip" data-placement="top"><img src={props.data.img2} alt="Sumatra" /></a></li> */}
                    {/* <li><a  data-placement="top"><img onClick={()=>setimgselect(props.data.img3)} style={{width:"100px",height:"80px"}}src={props.data.img3} alt="img3" /></a></li> */}
                    <li><a  data-placement="top"><img onClick={()=>setimgselect(props.data.img4)} style={{width:"100px",height:"80px"}}src={props.data.img4} alt="img4" /></a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <div className="col-lg-4 mb-5 mb-lg-0">

            <div className="row">
              <div className="col-12">
                <span className="eyebrow text-muted">WHOLE BEANS</span>
                <h1>{props.data.productname}</h1>

              </div>
            </div>

            <div className="row gutter-2">
              <div className="col-12  gutter-2">
                <div className="form-group">
                  <label>TYPE</label>
                  <span className="d-block text-dark">{props.data.Type}</span>
                </div>
              </div>
              <div className="row">
                <form id="form">
                  <label>SUBSCRIPTION PLAN</label><br></br>
                  <input type="radio" id="2-packets" name="subscription" value="2" checked={selectedOption === '2'} onChange={event => setSelectedOption(event.target.value)} />
                  <label for="child" className="ml-1 text-dark">2 packets for ₹{props.data.twopacket} </label><br></br>
                  <input type="radio" id="adult" name="subscription" value="6" checked={selectedOption === '6'} onChange={event => setSelectedOption(event.target.value)} />
                  <label for="adult" className="ml-1 text-dark">6 packets for ₹{props.data.sixpacket} (5% discount) </label><br></br>
                  <input type="radio" id="senior" name="subscription" value="12" checked={selectedOption === '12'} onChange={event => setSelectedOption(event.target.value)} />
                  <label for="senior" className="ml-1 text-dark">12 packets for ₹{props.data.twelvepackets} (5% discount) </label>
                </form>
              </div>

              {selectedOption === "2" && (
                <div className="row">
                  <form id="form">
                    <label>DELIVERY FREQUENCY</label><br></br>

                    <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                    <input type="radio" id="adult" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="adult" className="ml-1 text-dark">1 packet, every 30 days</label><br></br>

                  </form>


                </div>


              )}

              {selectedOption === "6" && props.data.coffeetype === "beans" && (
                <div className="row">
                  <form id="form">
                    <label>DELIVERY FREQUENCY</label><br></br>

                    <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                    <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">1 packet, every 30 days</label><br></br>
                    <input type="radio" id="adult" name="subscription" value="3" checked={selected2Option === '3'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="adult" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>

                  </form>


                </div>


              )}

              {selectedOption === "6" && props.data.coffeetype === "instant" && (
                <div className="row">
                  <form id="form">
                    <label>DELIVERY FREQUENCY</label><br></br>

                    <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                    <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>
                    <input type="radio" id="adult" name="subscription" value="3" checked={selected2Option === '3'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="adult" className="ml-1 text-dark">3 packets, every 30 days</label><br></br>

                  </form>


                </div>


              )}

              {selectedOption === "12" && props.data.coffeetype === "beans" && (
                <div className="row">
                  <form id="form">
                    <label>DELIVERY FREQUENCY</label><br></br>

                    <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">1 packet, every 15 days</label><br></br>
                    <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>
                  </form>


                </div>


              )}

              {selectedOption === "12" && props.data.coffeetype === "instant" && (
                <div className="row">
                  <form id="form">
                    <label>DELIVERY FREQUENCY</label><br></br>

                    <input type="radio" id="2-packets" name="subscription" value="1" checked={selected2Option === '1'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">2 packets, every 30 days</label><br></br>
                    <input type="radio" id="2-packets" name="subscription" value="2" checked={selected2Option === '2'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">3 packets, every 30 days</label><br></br>
                    <input type="radio" id="2-packets" name="subscription" value="3" checked={selected2Option === '3'} onChange={event => setSelected2Option(event.target.value)} />
                    <label for="child" className="ml-1 text-dark">4 packets, every 30 days</label><br></br>
                  </form>


                </div>


              )}



              <div className="col-12">
                <Link to="/new-subscription" className="btn btn-block btn-primary">Subscribe</Link>
              </div>
            </div>

          </div>

          <div className="col-lg-7">

            <div className="row">
              <div className="col">
                <div className="accordion" id="accordion-1">
                  <div className="card active">
                    <div className="card-header" id="heading-1-1">
                      <h5 className="mb-0">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse-1-1" aria-expanded="true" aria-controls="collapse-1-1">
                          Details
                        </button>
                      </h5>
                    </div>

                    <div id="collapse-1-1" className="collapse show" aria-labelledby="heading-1-1" data-parent="#accordion-1">
                      <div className="card-body">
                        <ul className="list list--unordered">
                          <li>Tasting Notes: {props.data.tastingnotes}</li>
                          <li>Origin: {props.data.origin}</li>
                        </ul>
                        <p>{props.data.desc}</p>
                        {/* <p>Sumatra has a full, buttery body with virtually no acidity, so the coffee's intensity and flavour simply lingers on your tongue. Make no mistake, this is a slurping coffee. At its best, there's no mistaking the concentrated herbal notes and earthy aroma - telltale marks this beloved brew have left on our shirts and our hearts.</p> */}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Prodcutdetails
