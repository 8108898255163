import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Prodcutdetails from '../components/Prodcutdetails'
import Productrelated from '../components/Productrelated'
import { useLocation } from "react-router-dom";
import alldata from "./data"
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {useParams } from 'react-router-dom';
import { useRef } from 'react';
import {Helmet} from "react-helmet";
import Header2 from '../components/Header2'
function Productdetailspage() {


  const [productitems, setproductItems] = useState({});
  let {product} = useParams();


  useEffect(() => {
    const productitems = JSON.parse(localStorage.getItem('productitems'));
    console.log(productitems)
    if (productitems) {
      setproductItems(productitems);
    }
    window.scrollTo(0, 0)
  }, [product]);


  return (
    <>
     <Helmet>
     <script  src={`${process.env.PUBLIC_URL+"/assets/js/vendor.min.js"}`} ></script>
    <script src={`${process.env.PUBLIC_URL+"/assets/js/app.js"}`}></script>
    </Helmet>
      <Header2 />
      <Prodcutdetails data = {productitems}/>
      <Productrelated  alldata = {alldata} productout ={productitems}/>
      <Footer />
    </>
   
  )
}

export default Productdetailspage
