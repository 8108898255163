import React from 'react'
import {useNavigate} from "react-router-dom"
function Passwordpage() {

    const [password, setPassword] = React.useState('');
    const navigate = useNavigate();
    const handleSubmit = event => {
        event.preventDefault();
        // submit the form and reset the state
        if(password=='blobcity'){
            localStorage.setItem('password', JSON.stringify('password'));
            console.log('sdasd')
            navigate("/")
        }else{
            alert('Wrong Password !! Enter the correct password..')
        }
        console.log(password)
        
      };

    return (
        <div class="card d-flex justify-content-center" style={{width: "30rem", margin: 'auto'}}>
            <div class="card-body">
                <h4 class="card-title">Enter Password to Access Website</h4>
                <form onSubmit={handleSubmit}>
                    {/* <div class="form-group">
                        <label for="email">Email address</label>
                        <input type="email" class="form-control" id="email" placeholder="Enter email" />
                    </div> */}
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="Password" value={password}
                       onChange={event => setPassword(event.target.value)}/><br></br>
                    </div>
                    <button type="submit" class="btn btn-primary d-flex  align-items-center" style={{ margin: 'auto'}}>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default Passwordpage