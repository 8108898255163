import React from 'react'


import sumtraimage from '../images/products/1.png'
import indianestateblendimage from '../images/products/2.png'
import readybewitalianimage from '../images/products/italian-roast.png'
import colombiaimage from '../images/products/colombia.png'
import italianroastimage from '../images/products/4.png'
import kenyaimage from '../images/products/3.png'
import backimage from '../images/background.png'
import mobilebackimage from '../images/background_header_mobile.png'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Homepage(props) {

    console.log(props.data)
    const navigate = useNavigate();

    function onhandleClick(data){
        localStorage.setItem('productitems', JSON.stringify(data));
        navigate(`/${data.link}`)
    }
    let productlist = props.data.map((data) => {
        return <div className="col-6 col-md-4" onClick={()=>onhandleClick(data)}>
            <div className="card card-product">
                <figure className="card-image">
                    <Link >
                        <img src={data.img} alt="Image" />
                    </Link>
                </figure>
                <div className="card-footer">
                    <h3 className="card-title"><Link>{data.productname}</Link></h3>
                    <span className="brand">{data.Type}</span>
                    {data.coffeetype==="beans"? <span className="brand">250gms <i className="pl-8 icon-coffee"></i>25</span>: <span className="brand">12 sticks</span>}
                    <span className="price">₹{data.Price}</span>
                </div>
            </div>
        </div>
    })

    return (
        <>

            <div class="swiper-slide">
                <div>
                    <picture>
                        <source media="(min-width: 1440px)"
                            srcset={backimage} />
                        <source media="(min-width: 1024px)"
                            srcset={backimage} />
                        <source media="(min-width: 768px)"
                            srcset={backimage} />
                        <source media="(min-width: 0px)"
                            srcset={mobilebackimage} />
                        <img class="lazypreload lazyloaded" data-src={backimage} alt="" style={{ maxWidth: "103%" }} />
                    </picture>
                </div>


                <div class="container text-center pt-2">

                    <div class="row gutter-1 align-items-end">
                        <div class="col-md-6">
                            {/* <h1>Subscribe to Starbucks</h1> */}
                        </div>
                    </div>


                    <div
                        class="">
                        <p class="pb-2" style={{
                            color: "#1e3932",
                            fontSize: "20px",
                            lineHeight: "20px",
                            fontFamily: `PikeBold, Arial`,
                            fontWeight: 700,
                            letterSpacing: ".15em"
                        }}>#SubscribeToStarbucks</p>
                        <p class="" style={{
                            color: "#1e3932",
                            fontSize: "20px",
                            lineHeight: "20px",
                            fontFamily: `PikeBold, Arial`,
                            fontWeight: 700,
                            letterSpacing: ".15em"
                        }}
                        >FIND THE ONE THAT'S</p>
                        <div class="row gutter-1 align-items-end">
                            <div class="col-md-12">
                                <h1>Made to be Yours</h1>
                            </div>
                            <div class="col-md-12">
                                <p class="text-center" style={{ color: "#1e3932", fontSize: "1.15em" }}>Whether your style is light to dark, <br></br> smooth to
                                    rich or balanced to robust, <br></br> we have a flavour that's perfect for you</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <section className="pt-6">
                <div className="container">


                    <div className="row gutter-1 align-items-end">
                        <div className="col-md-6">
                            {/* <h1>Subscribe to Starbucks</h1> */}
                        </div>
                    </div>





                    <div className="row gutter-1">

                        {/* <div className="col-6 col-md-4">
                            <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="/product">
                                        <img src={sumtraimage} alt="Image" />
                                    </Link>
                                </figure>
                                <div className="card-footer">
                                    <h3 className="card-title"><Link to="/product">Sumatra</Link></h3>
                                    <span className="brand">Dark Roast</span>
                                    <span className="price">₹750</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="./product" target="_blank">
                                        <img src={indianestateblendimage} alt="India Estates Blend" />
                                    </Link>

                                </figure>
                                <div className="card-footer">
                                    <h3 className="card-title"><Link to="/product">India Estates Blend</Link></h3>
                                    <span className="brand">Medium Roast</span>
                                    <span className="price">₹750</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="/product">
                                        <img src={kenyaimage} alt="Image" />
                                    </Link>

                                </figure>
                                <div className="card-footer">
                                    <h3 className="card-title"><Link to="/product">Kenya</Link></h3>
                                    <span className="brand">Medium Roast</span>
                                    <span className="price">₹750</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="/product">
                                        <img src={italianroastimage} alt="Image" />
                                    </Link>

                                </figure>
                                <div className="card-footer">
                                    <h3 className="card-title"><Link to="/product">Italian Roast</Link></h3>
                                    <span className="brand">Dark Roast</span>
                                    <span className="price">₹750</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="/product">
                                        <img src={colombiaimage} alt="Image" />
                                    </Link>

                                </figure>
                                <div className="card-footer">
                                    <h3 className="card-title"><Link to="/product">Colombia</Link></h3>
                                    <span className="brand">VIA Ready Brew - Medium Roast</span>
                                    <span className="price">₹650</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4">
                            <div className="card card-product">
                                <figure className="card-image">
                                    <Link to="/product">
                                        <img src={readybewitalianimage} alt="Image" />
                                    </Link>

                                </figure>
                                <div className="card-footer">
                                    <h3 className="card-title"><Link to="/product">Italian Roast</Link></h3>
                                    <span className="brand">VIA Ready Brew - Dark Roast</span>
                                    <span className="price">₹650</span>
                                </div>
                            </div>
                        </div> */}
                        {productlist}
                    </div>
                </div>

            </section>


        </>
    )
}

export default Homepage
