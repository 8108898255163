import React from 'react'

function Orderdetails() {
    return (
        <section style={{padding: "2rem 0"}}>
      <div className="container ">
        
        <div className="row gutter-1 gutter-md-2">
          

          <div className="col-lg-8">
            <div className="bg-white p-2 p-md-3">
              <div className="tab-content" id="myTabContent">

              
                <div className="tab-pane fade active show" id="sidebar-1-1" role="tabpanel" aria-labelledby="sidebar-1-1">
                  <div className="row align-items-end">
                    <div className="col">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item  badge bg-primary text-white">Active Order</li>
                         
                        </ol>
                      </nav>
                      <br />
                      <h3 className="cart-item-title">India Estates Blend</h3>
                      <i className="icon icon-truck" style={{width:"100%", textAlign:"left"}}><small
                          className="cart-item-subtitle">&nbsp;Next delivery: 30 Sep 2022 estimated</small></i>
                    </div>

                  </div>

          
                  <div className="row">
                    <div className="col">
                      <div className="bordered">
                        <ul className="order-meta">
                   
                          <li>
                            <h5 className="order-meta-title">Order #</h5>
                            <span>12339201</span>
                          </li>
                          <li>
                            <h5 className="order-meta-title">Date</h5>
                            <span>23 July 2022</span>
                          </li>
                          <li>
                            <h5 className="order-meta-title">Total</h5>
                            <span>₹4275</span>
                          </li>
                          <li>
                            <h5 className="order-meta-title">Delivered</h5>
                            <span>2 out of 6</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-md-6">
                      <a href="" className="btn btn-outline-primary text-center col-md-6"
                        style={{backgroundColor:"#006241", color: "white"}}>Reorder</a>
                    </div>
                    <div className="col col-md-6">
                      <a href="./account-order-view-details.html" className="btn btn-outline-primary text-right">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        
        </div>
        </div>
    </section>
    )
}

export default Orderdetails