import React from 'react'
import logo from '../images/logo.png'

import { Link } from "react-router-dom";

function Header2() {
    function logouthandler(){
        localStorage.removeItem('password')
        window.location.reload()
    }
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link to="/" className="navbar-brand order-1 order-lg-2"><img style={{ width: "300px" }} src={logo} alt="Logo" /></Link>

                        <div className="collapse navbar-collapse order-5 order-lg-3" id="navbarMenu2">
                            <ul className="navbar-nav ml-auto position-relative">


                                {/* <li className="nav-item dropdown dropdown-md dropdown-hover">
                                    <Link className="nav-icon dropdown-toggle" id="navbarDropdown-4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="icon-search d-none d-lg-inline-block"></i>
                                        <span className="d-inline-block d-lg-none">Search</span>
                                    </Link>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown-4">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="searchForm" placeholder="Search for items and brands" />
                                        </div>
                                    </div>
                                </li>

                                <li className="d-none d-lg-inline nav-item dropdown dropdown-md dropdown-hover">
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown-5">
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="select-frame">
                                                        <select className="custom-select custom-select-lg" id="countrySelect1">
                                                            <option value="1">United States</option>
                                                            <option value="2">Germany</option>
                                                            <option value="3">France</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="select-frame">
                                                        <select className="custom-select custom-select-lg" id="curencySelect1">
                                                            <option value="1">USD</option>
                                                            <option value="2">EUR</option>
                                                            <option value="3">RUB</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </li> */}


                                <li className="nav-item dropdown dropdown-md dropdown-hover">
                                    <Link className="nav-icon dropdown-toggle" to="/login" target="_blank">
                                        Login
                                        {/* <i className="icon-user d-none d-lg-inline-block"></i>
                                        <Link href="./login.html" className="d-inline-block d-lg-none" target="_blank">Account</Link> */}
                                    </Link>

                                </li>


                                <li className="nav-item dropdown dropdown-md dropdown-hover">
                                    <Link className="nav-icon" role="button" to="/my-subscription">
                                        {/* <i className="icon-shopping-bag d-none d-lg-inline-block"></i>
                                        <span className="d-inline-block d-lg-none">Bag</span> */}
                                       My Subscription
                                    </Link>

                                </li>
                                <li className="nav-item dropdown dropdown-md dropdown-hover">
                            <Link className="nav-icon" role="button" onClick={()=>logouthandler()}>
                                {/* <i className="icon-user d-none d-lg-inline-block"></i> */}
                                Access out
                                {/* <Link to="/order" className="d-inline-block d-lg-none" target="_blank">Orders</Link> */}
                            </Link>

                        </li>
                            </ul>
                        </div>

                        <div className="order-2 d-flex d-lg-none" id="navbarMenuMobile">
                            <ul className="navbar-nav navbar-nav--icons ml-auto position-relative">


                                {/* <li className="nav-item">
                                    <Link href="" className="nav-icon"><i className="icon-search"></i></Link>
                                </li>


                                <li className="nav-item dropdown dropdown-md dropdown-hover">
                                    <Link href="" className="nav-icon"><i className="icon-shopping-bag"></i></Link>
                                </li> */}


                                <li className="nav-item dropdown dropdown-md dropdown-hover">
                                    <Link href="" className="nav-icon" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="icon-menu"></i>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}
export default Header2
