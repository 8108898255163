import React from 'react'
import mainlogo from "../images/logo_h100.png"
import { Link } from "react-router-dom";
function Login (){
    return (
        <>
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div className="card boxed">
                            <div className="card-header">
                                <ul className="nav nav-tabs nav-fill card-header-tabs lavalamp" id="component-1" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#component-1-1" role="tab" aria-controls="component-1-1" aria-selected="true">Sign In</a>
                                    </li>

                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="component-1-content">
                                    <div className="col-12 py-1" style={{fontSize:"90%"}}><small>An OTP will be sent to your mobile number for verification</small></div>
                                    <div className="tab-pane fade show active" id="component-1-1" role="tabpanel" aria-labelledby="component-1-1">
                                        <div className="row gutter-2">
                                            <div className="col-12">
                                                <fieldset>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-label-group">
                                                                <input type="text" id="inputPhone" className="form-control form-control-lg" placeholder="Email address" required="" maxlength="10" />
                                                                <label for="inputPhone">Enter mobile number</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </fieldset>
                                            </div>

                                            <div className="col-12">
                                                <Link to="/otp" className="btn btn-primary btn-block">Get OTP</Link>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Login
